import React, { useState, useEffect } from "react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider, useTheme } from "@mui/material";
import { Routes, Route, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";
import { tokens } from "./theme";

// COMPONENTES SIIF

import Login from "./pages/login/Login";
import Catalogos from "./pages/catalogos/Catalogos";
import Empresas from "./pages/empresas/Empresas";
import Operadores from "./pages/operadores/Operadores";
import Empleados from "./pages/empleados/Empleados";
import Sucursales from "./pages/sucursales/Sucursales";
import Bancos from "./pages/bancos/Bancos";
import Codigoauth from "./pages/codigoAuth/Codigoauth";
import CargarSaldoBancos from "./pages/saldobancos/CargarSaldoBancos";
import AnalyticsBancos from "./pages/analytics/analyticsBancos";
import DasboardBancos from "./pages/saldobancos/DashboardBancos";
import Permisos from "./pages/permisos/Permisos";

import CargaInfoRts from "./pages/cargainfo/CargaInfoRts";
import CarteraVencida from "./pages/carteravencida/CarteraVencida";
import EstadoCuenta from "./pages/estadocuenta/EstadoDeCuenta";
import CargaInfoRts2 from "./pages/cargainfo/CargaInfoRts2";
import Comparativa from "./pages/comparativa/Comparativa";

import Clientes from "./pages/clientes/Clientes";
import Ejecutivos from "./pages/ejecutivos/Ejecutivos";
import Relaciones from "./pages/relacion/RelacionEjecutivoCliente";

import GraficaComparativa from "./pages/comparativa/GraficaComparativa";
import CargaInfoMicro from "./pages/cargainfo/CargaInfoMicro";

import ComparativaOrdenes from "./pages/ordenes/Comparativa";
import OrdenesConciliadas from "./pages/ordenes/vistas/OrdenesConciliadas";
function App() {
   const theme2 = useTheme();
  const colors = tokens(theme2.palette.mode);
  // Verifica si la cookie está presente
  function cookieEstaActiva() {
    if (Cookies.get("login") === "1") {
      return true;
    } else {
      return false;
    }
  }

  // Previene el retroceso de página si la cookie está activa
  function prevenirRetroceso() {
    if (cookieEstaActiva()) {
      window.history.pushState(null, null, window.location.href);
      window.addEventListener("popstate", function (event) {
        // Restaura el estado actual si el usuario intenta retroceder
        window.history.pushState(null, null, window.location.href);
      });
    }
  }

  const [theme, colorMode] = useMode();

  const navigate = useNavigate();

  // ===============================================
  // LOGIN
  // ===============================================

  const [showLogin, setShowLogin] = useState(true);

  useEffect(() => {
    if (Cookies.get("login") === "1") {
      setShowLogin(false);

      //navigate('/dashboard');
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {

    prevenirRetroceso()
  
  },[showLogin])

  // ===============================================

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <div className="app">
            {/*{showLogin ? null : <Sidebar />}*/}
            {showLogin ? null : (
              <>
                {!window.location.pathname.includes('/cargarrts') &&
                !window.location.pathname.includes('/cargarmic') &&
                !window.location.pathname.includes('/cargarrts2') && <Sidebar />}
              </>
            )}

            <main className="content"
              style={{
                backgroundColor: colors.primary[1000], 
                minHeight: '100vh',
                overflow: 'auto'
              }}
            >
              {/*{showLogin ? null : <Topbar />}*/}
              {showLogin ? null : (
                <>
                  {!window.location.pathname.includes('/cargarrts') &&
                  !window.location.pathname.includes('/cargarmic') &&
                !window.location.pathname.includes('/cargarrts2') && <Topbar />}
                </>
              )}


              <Routes>
                <Route path="/" element={<Login />} />

                <Route
                  path="/auth"
                  element={<Codigoauth setShowLogin={setShowLogin} />}
                />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/catalogos" element={<Catalogos />} />
                <Route path="/loadfile" element={<CargarSaldoBancos />} />
                <Route path="/empresas" element={<Empresas />} />

                <Route path="/operadores" element={<Operadores />} />
                <Route path="/empleados" element={<Empleados />} />
                <Route path="/sucursales" element={<Sucursales />} />
                <Route path="/analitycs" element={<AnalyticsBancos />} />
                <Route path="/bancos" element={<Bancos />} />
                <Route path="/dashboardBancos" element={<DasboardBancos />} />
                <Route path="/permisos" element={<Permisos />} />

                <Route path="/cargarrts" element={<CargaInfoRts />} />
                <Route path="/cargarrts2" element={<CargaInfoRts2 />} />
                <Route path="/carteravencida" element={<CarteraVencida />} />
                <Route path="/estadocuenta" element={<EstadoCuenta />} />
                <Route path="/comparativa" element={<Comparativa />} />
                <Route path="/clientes" element={<Clientes />} />
                <Route path="/ejecutivos" element={<Ejecutivos />} />
                <Route path="/relaciones" element={<Relaciones />} />
                <Route path="/graficacomparativa/:empresa" element={<GraficaComparativa />} />
                <Route path="/cargarmic" element={<CargaInfoMicro />} />
                {/* Ordenes */}
                <Route path="/ordencomparativa" element={<ComparativaOrdenes />} />
                <Route path="/ordenesconciliadas" element={<OrdenesConciliadas />} />

              </Routes>
            </main>
          </div>
        </CssBaseline>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
